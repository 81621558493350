<template>
  <van-popup v-bind="$attrs" :round="round" :position="position" :style="popupStyle" v-on="$listeners" closeable>
    <div style="margin-top: 50px;padding: 0px 10px;">
      <slot />
    </div>
  </van-popup>
</template>
<script>
export default {
  name: "Popup",
  props: {
    round: {
      type: Boolean,
      default: true
    },
    position: {
      type: String,
      default: "bottom"
    },
    popupStyle: {
      type: Object,
      default: () => {
        return { height: "30%" };
      }
    }
  }
};
</script>
<style></style>
