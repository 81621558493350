<template>
  <div>
    <div ref="qrcode" id="qrcode" style="display: flex;justify-content: center;" />
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
export default {
  name: "QRCode",
  props: {},
  data() {
    return {
      qrcode: null,
      text: null
    };
  },
  methods: {
    copy() {
      let clipboard = require("clipboardy");
      clipboard.writeSync(this.text);
    },
    createQRCode(text) {
      this.$refs.qrcode.innerHTML = "";
      this.qrcode = new QRCode("qrcode", {
        width: 150, // 二维码宽度
        height: 150, // 二维码高度
        colorLight: "#ffffff",
        text: ""
      });
      this.qrcode.makeCode(text);
      this.text = text;
    }
  }
};
</script>
<style></style>
